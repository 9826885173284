<template>
  <div class="app docs">
    <Navigation/>
    <div class="home-content docs-content">
      <iframe src="/docs/#/" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import Navigation from '../../components/HomeNav.vue'

export default {
  name: 'Docs',
  components: {
    Navigation
  }
}
</script>

<style lang="scss" scoped>
.docs-content {
  padding: 0 !important;
  overflow: hidden !important;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
